import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import { Tables } from "@/types/database.ts";

import { Badge } from "../../catalyst/badge.tsx";
import GenerateMomentsIdeas from "./GenerateMomentsIdeas.tsx";
import SaveAll from "./SaveAll.tsx";
import { SpokableButton } from "../SpokableButton.tsx";

export interface GenerateBeatModalProps {
  story: Tables<"blueprint_stories"> | null;
  characters: Tables<"blueprint_characters">[];
  scene: Tables<"blueprint_scenes"> | null | undefined;
}

const GenerateMomentsModal: React.FC<GenerateBeatModalProps> = ({ story, scene, characters }) => {
  const {
    isOpen,
    stepIndex,
    setStepIndex,
    handleClose,
    moments,
    momentStatus,
    generateMoments,
    setMoments,
  } = useGenerateSteps();

  const maxStepIndex = 1;
  const renderStepContent = () => {
    switch (stepIndex) {
      case 0:
        return (
          <GenerateMomentsIdeas
            status={momentStatus}
            generateMoments={generateMoments}
            setMoments={setMoments}
            scene={scene}
            moments={moments}
            characters={characters}
            audience={story?.audience || ""}
          />
        );
      case 1:
        return <SaveAll moments={moments} scene={scene} story={story} handleClose={handleClose} />;

      default:
        return null;
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 font-nunito" onClose={handleClose}>
        <div className="fixed inset-0 bg-black/60" aria-hidden="true" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6">
            <Dialog.Panel className="w-2/3 max-h-[95vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all">
              {scene && (
                <div className="pt-6 ml-6">
                  <Badge>{scene.name}</Badge>
                </div>
              )}
              <div className="p-6">
                {renderStepContent()}
                <div className="mt-4 flex justify-between">
                  <div>
                    {stepIndex > 0 && (
                      <SpokableButton
                        color="light"
                        onClick={() => setStepIndex(Math.max(0, stepIndex - 1))}
                        className="mr-2"
                      >
                        Previous
                      </SpokableButton>
                    )}
                    {stepIndex < maxStepIndex && (
                      <SpokableButton onClick={() => setStepIndex(stepIndex + 1)}>
                        Next
                      </SpokableButton>
                    )}
                  </div>
                  <SpokableButton color="light" onClick={handleClose}>
                    Close
                  </SpokableButton>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GenerateMomentsModal;
