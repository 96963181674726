import React from "react";
import { Card } from "@/components/ui/card";
import { LucideIcon } from "lucide-react";

interface DraggableCardProps {
  icon: LucideIcon;
  label: string;
  nodeType: string;
  onDragStart: (event: React.DragEvent<HTMLDivElement>, nodeType: string) => void;
}

const DraggableCard: React.FC<DraggableCardProps> = ({
  icon: Icon,
  label,
  nodeType,
  onDragStart,
}) => {
  return (
    <Card
      draggable
      onDragStart={(e) => onDragStart(e, nodeType)}
      className="px-4 py-2 text-sm text-center cursor-move hover:bg-black hover:text-white"
    >
      <div className="flex items-center gap-2">
        <Icon className="w-5 h-5" />
        {label}
      </div>
    </Card>
  );
};

export default DraggableCard;
