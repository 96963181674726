import { useState } from "react";
import { useRecoilState } from "recoil";
import { modalScenesGenerateState } from "@/states/ModalState.ts";
import { useApiCall } from "@/components/admin/generateStory/useGenerateHooks.ts";
import { BeatSheetOutput } from "@/types/beatsheet_prompts_generated_types.ts";
import { SceneWithoutInteractivityOutput } from "@/types/scene_prompts_generated_types.ts";

export const useGenerateSteps = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalScenesGenerateState);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const useGenerateBeatSheet = () => useApiCall<BeatSheetOutput>("generate_beat_sheet");
  const useGenerateScenes = () => useApiCall<SceneWithoutInteractivityOutput>("generate_scene");
  
  const {
    data: generatedBeatsheet,
    status: beatsheetStatus,
    callApi: generateBeatsheet,
  } = useGenerateBeatSheet();

  const {
    data: generatedScene,
    status: sceneStatus,
    callApi: generateScene,
    setData: setGeneratedScene,
  } = useGenerateScenes();

  const handleClose = () => {
    setIsOpen(false);
    setStepIndex(0);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    generatedBeatsheet,
    beatsheetStatus,
    generateBeatsheet,
    generatedScene,
    sceneStatus,
    generateScene,
    setGeneratedScene,

    handleClose,
  };
};
