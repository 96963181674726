import React from "react";
import { NodeProps } from "@xyflow/react";
import { Trophy } from "lucide-react";
import { BaseNode } from "@/components/admin/storyGraph/common/BaseNode.tsx";
import { MomentNode } from "@/components/admin/storyGraph/common/types.ts";

const BonusNode: React.FC<NodeProps<MomentNode>> = (props) => {
  return (
    <BaseNode {...props} borderColor="rgb(147 197 253)">
      <div className="flex items-center justify-center gap-2">
        <Trophy className="w-5 h-5 text-blue-500" />
        <span>{props.data.label}</span>
      </div>
    </BaseNode>
  );
};

export default BonusNode;
