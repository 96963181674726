// src/components/admin/storyGraph/common/BaseNode.tsx
import React from "react";
import { Handle, Position } from "@xyflow/react";
import { BaseNodeProps } from "@/components/admin/storyGraph/common/types.ts";

export const BaseNode: React.FC<BaseNodeProps> = ({ isConnectable, borderColor, children }) => {
  return (
    <div className={`bg-white border-2 rounded-lg p-4 w-80 min-h-40`} style={{ borderColor }}>
      <Handle type="target" position={Position.Left} isConnectable={isConnectable} />
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
      <Handle type="source" position={Position.Right} isConnectable={isConnectable} />
      <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
      {children}
    </div>
  );
};
