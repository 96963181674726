import { useState } from "react";
import { useRecoilState } from "recoil";
import { modalMomentsGenerateState } from "../../../states/ModalState.ts";
import { useApiCall } from "@/components/admin/generateStory/useGenerateHooks.ts";
import { Moments } from "@/types/fastApiTypes.ts";

export const useGenerateSteps = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalMomentsGenerateState);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const useGenerateMoments = () => useApiCall<Moments>("generate_moments_ideas");

  const {
    data: moments,
    status: momentStatus,
    callApi: generateMoments,
    setData: setMoments,
  } = useGenerateMoments();

  const handleClose = () => {
    setIsOpen(false);
    setStepIndex(0);
    setMoments(null);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    moments,
    momentStatus,
    generateMoments,
    setMoments,

    handleClose,
  };
};
