import React, { useEffect, useState } from "react";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { Tables, TablesInsert } from "@/types/database.ts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import AutoResizeTextArea from "@/components/admin/AutoResizeTextArea.tsx";
import { BeatSheetInput, BeatSheetOutput } from "@/types/beatsheet_prompts_generated_types.ts";
import { unSnakeCase } from "@/utils/stringUtil.ts";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";

interface BeatGeneratorProps {
  story: Tables<"blueprint_stories"> | null;
  status: string;
  beats: Tables<"blueprint_beats">[];
  beatsheets: Tables<"blueprint_beatsheets">[];
  branch: Tables<"blueprint_branches"> | null | undefined;
  characters: Tables<"blueprint_characters">[];
  previousScenes: Tables<"blueprint_scenes">[] | null;
  setSelectedBeatsheet: React.Dispatch<
    React.SetStateAction<TablesInsert<"blueprint_beatsheets"> | null>
  >;
  generateBeatsheet: (payload: any) => void;
  generatedBeatsheet: BeatSheetOutput | null;
  selectedBeatsheet: TablesInsert<"blueprint_beatsheets"> | null;
}

const BeatGenerator: React.FC<BeatGeneratorProps> = ({
  story,
  status,
  beats,
  beatsheets,
  branch,
  characters,
  previousScenes,
  generatedBeatsheet,
  generateBeatsheet,
  setSelectedBeatsheet,
  selectedBeatsheet,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedBeatId, setSelectedBeatId] = useState<string>("");

  // Get available beats for selection
  const getAvailableBeats = () => {
    // Sort beats by beat_order
    const sortedBeats = [...beats].sort((a, b) => a.beat_order - b.beat_order);

    // Get all beats that have beatsheets
    const beatsWithSheets = new Set(beatsheets.map((sheet) => sheet.beat_id));

    // Find the next beat without a beatsheet based on beat_order
    const nextBeatWithoutSheet = sortedBeats.find((beat) => !beatsWithSheets.has(beat.id));

    // Create a set of selectable beat IDs
    const selectableBeats = new Set(beatsWithSheets);
    if (nextBeatWithoutSheet) {
      selectableBeats.add(nextBeatWithoutSheet.id);
    }

    // Filter and sort the available beats by beat_order
    return sortedBeats.filter((beat) => selectableBeats.has(beat.id));
  };

  const handleBeatSelect = (beatId: string) => {
    setSelectedBeatId(beatId);
    const matchingSheet = beatsheets.find((sheet) => sheet.beat_id === beatId);
    setSelectedBeatsheet(matchingSheet || null);
  };

  useEffect(() => {
    if (!branch) return;

    const newBeatSheet: TablesInsert<"blueprint_beatsheets"> = {
      beat_id: selectedBeatId,
      story_id: story?.id,
      branch_id: branch?.id,
      description: JSON.stringify(generatedBeatsheet),
    };
    setSelectedBeatsheet(newBeatSheet);
  }, [generatedBeatsheet, setSelectedBeatsheet]);

  const handleGenerate = () => {
    console.log("selectedBeatId", story);
    if (selectedBeatId && branch && story) {
      setIsGenerating(true);
      const beat = beats.find((beat) => beat.id === selectedBeatId);
      const params: BeatSheetInput = {
        one_pager: JSON.stringify(branch.one_pager) || "",
        character_list: JSON.stringify(characters),
        audience: story.audience || "",
        previous_scenes: JSON.stringify(previousScenes),
        previous_beatsheets: JSON.stringify(beatsheets),
        beat_to_generate: beat?.name || "",
      };

      generateBeatsheet(params);
    }
  };

  useEffect(() => {
    if (status === "") {
      setIsGenerating(false);
    }
  }, [status]);

  // Get available beats for the dropdown
  const availableBeats = getAvailableBeats();

  return (
    <div className="w-full max-w-4xl mx-auto p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl font-bold">
          Beat Generator
          <span className="text-sm ml-2 text-gray-500">{status}</span>
        </h3>
      </div>

      <div className="space-y-6">
        {/* Beat Selection */}
        <div className="w-full">
          <label className="block text-sm font-medium mb-2">Select Beat</label>
          <Select onValueChange={handleBeatSelect} value={selectedBeatId}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Choose a beat..." />
            </SelectTrigger>
            <SelectContent>
              {availableBeats.map((beat) => (
                <SelectItem key={beat.id} value={beat.id}>
                  {unSnakeCase(beat.name)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {/* Beatsheet Display or Generate Button */}
        <div className="w-full">
          {selectedBeatId && selectedBeatsheet && (
            <div className="space-y-2">
              <label className="block text-sm font-medium">Beatsheet Description</label>
              <AutoResizeTextArea
                value={selectedBeatsheet.description || ""}
                onChange={() => console.log("change")}
              />
            </div>
          )}
          {selectedBeatId && (
            <div className="mt-4">
              <SpokableButton
                onClick={handleGenerate}
                disabled={isGenerating}
                className="inline-flex items-center"
              >
                {isGenerating && <ArrowPathIcon className="w-4 h-4 mr-2 animate-spin" />}
                Generate Beatsheet
              </SpokableButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BeatGenerator;
