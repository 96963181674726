// src/components/admin/storyGraph/sceneLevel/nodes/CraftingNode.tsx
import React from "react";
import { NodeProps } from "@xyflow/react";
import { Blend } from "lucide-react";
import { BaseNode } from "@/components/admin/storyGraph/common/BaseNode.tsx";
import { MomentNode } from "@/components/admin/storyGraph/common/types.ts";

const CraftingNode: React.FC<NodeProps<MomentNode>> = (props) => {
  return (
    <BaseNode borderColor="rgb(134 239 172)" {...props}>
      <div className="flex items-center justify-center gap-2 relative">
        <Blend className="w-5 h-5 text-green-500" />
        <span>{props.data.label}</span>
      </div>
    </BaseNode>
  );
};

export default CraftingNode;
