import { DragEvent, useCallback, useEffect, useState } from "react";
import { MarkerType, useEdgesState, useNodesState, useReactFlow } from "@xyflow/react";

import { useConnectionHandling } from "@/components/admin/storyGraph/layout/useConnectionHandling";
import { useFlowLayout } from "@/components/admin/storyGraph/layout/useFlowLayout";
import { Tables } from "@/types/database";
import { getRandomTailwindColor } from "@/utils/color";
import {
  MOMENT_NODE_TYPES,
  MomentEdge,
  MomentNode,
  MomentType,
  MONOLOGUE,
  SCENE,
  SCENE_NODE_TYPES,
  SceneEdge,
  SceneNode,
  SceneType,
} from "@/components/admin/storyGraph/common/types.ts";

interface StoryFlowData {
  moments: Tables<"blueprint_moments">[];
  transitions: Tables<"blueprint_moment_transitions">[];
  scenes: Tables<"blueprint_scenes">[];
  beats: Tables<"blueprint_beats">[];
  beatsheets: Tables<"blueprint_beatsheets">[];
  branches: Tables<"blueprint_branches">[];
  characters: Tables<"blueprint_characters">[];
}

const momentColorMap = new Map<string, string>();

const createMomentNodes = (moments: Tables<"blueprint_moments">[]): MomentNode[] => {
  return moments.map((moment, index) => {
    const borderColor = "#000000";
    const nodeType = (moment.moment_type as MomentType) || MONOLOGUE;

    return {
      id: moment.id,
      type: nodeType,
      data: {
        label: moment.moment_name || "",
        type: nodeType,
        isInitialized: true,
        sceneId: moment.scene_id,
        content: moment.moment_setting,
        conditions: [], // Add actual conditions if available
        triggers: [], // Add actual triggers if available
        borderColor,
      },
      position: { x: 100, y: index * 100 },
      style: { border: "none" },
    };
  });
};

const createSceneNodes = (
  scenes: Tables<"blueprint_scenes">[],
  beatsheets: Tables<"blueprint_beatsheets">[],
  beats: Tables<"blueprint_beats">[],
  branches: Tables<"blueprint_branches">[],
): SceneNode[] => {
  return scenes.map((scene, index) => {
    const beatsheet = beatsheets.find((bs) => bs.id === scene.beatsheet_id);
    const beat = beatsheet ? beats.find((b) => b.id === beatsheet.beat_id) : null;
    const branch = beatsheet ? branches.find((br) => br.id === beatsheet.branch_id) : null;

    return {
      id: `scene-${scene.id}`,
      type: SCENE,
      data: {
        label: scene.name,
        type: SCENE,
        isInitialized: true,
        sceneOrder: scene.scene_order,
        beatsheet: beatsheet,
        beat: beat,
        branch: branch,
        scene: scene,
      },
      position: { x: 100, y: index * 100 },
      style: { border: "none" },
    };
  });
};

const createSceneEdges = (
  scenes: Tables<"blueprint_scenes">[],
  beatsheets: Tables<"blueprint_beatsheets">[],
  beats: Tables<"blueprint_beats">[],
  branches: Tables<"blueprint_branches">[],
): SceneEdge[] => {
  const edges: SceneEdge[] = [];

  // Group scenes by beatsheet
  const scenesByBeatsheet = scenes.reduce(
    (acc, scene) => {
      const beatsheetId = scene.beatsheet_id;
      if (!acc[beatsheetId]) acc[beatsheetId] = [];
      acc[beatsheetId].push(scene);
      return acc;
    },
    {} as Record<string, Tables<"blueprint_scenes">[]>,
  );

  Object.entries(scenesByBeatsheet).forEach(([beatsheetId, beatsheetScenes]) => {
    const sortedScenes = [...beatsheetScenes].sort((a, b) => a.scene_order - b.scene_order);
    const beatsheet = beatsheets.find((bs) => bs.id === beatsheetId);
    const beat = beatsheet ? beats.find((b) => b.id === beatsheet.beat_id) : null;
    const branch = beatsheet ? branches.find((br) => br.id === beatsheet.branch_id) : null;

    for (let i = 0; i < sortedScenes.length - 1; i++) {
      edges.push({
        id: `scene-${sortedScenes[i].id}-${sortedScenes[i + 1].id}`,
        source: `scene-${sortedScenes[i].id}`,
        target: `scene-${sortedScenes[i + 1].id}`,
        type: "smoothstep",
        data: {
          fromBeatsheet: beatsheet,
          toBeatsheet: beatsheet,
          fromBeat: beat,
          toBeat: beat,
          branch: branch,
          fromScene: sortedScenes[i],
          toScene: sortedScenes[i + 1],
          isIntraBeatsheet: true,
        },
      });
    }
  });

  // Group beatsheets by branch
  const beatsheetsByBranch = beatsheets.reduce(
    (acc, beatsheet) => {
      if (!acc[beatsheet.branch_id]) {
        acc[beatsheet.branch_id] = [];
      }
      acc[beatsheet.branch_id].push(beatsheet);
      return acc;
    },
    {} as Record<string, Tables<"blueprint_beatsheets">[]>,
  );

  // Create edges between beatsheets within each branch
  Object.entries(beatsheetsByBranch).forEach(([branchId, branchBeatsheets]) => {
    const sortedBeatsheets = [...branchBeatsheets].sort((a, b) => {
      const beatA = beats.find((beat) => beat.id === a.beat_id);
      const beatB = beats.find((beat) => beat.id === b.beat_id);
      return (beatA?.beat_order || 0) - (beatB?.beat_order || 0);
    });

    for (let i = 0; i < sortedBeatsheets.length - 1; i++) {
      const currentBeatsheetScenes = scenesByBeatsheet[sortedBeatsheets[i].id] || [];
      const nextBeatsheetScenes = scenesByBeatsheet[sortedBeatsheets[i + 1].id] || [];

      if (currentBeatsheetScenes.length > 0 && nextBeatsheetScenes.length > 0) {
        const lastScene = [...currentBeatsheetScenes]
          .sort((a, b) => a.scene_order - b.scene_order)
          .pop();
        const firstScene = [...nextBeatsheetScenes].sort(
          (a, b) => a.scene_order - b.scene_order,
        )[0];

        if (lastScene && firstScene) {
          const fromBeatsheet = beatsheets.find((bs) => bs.id === sortedBeatsheets[i].id);
          const toBeatsheet = beatsheets.find((bs) => bs.id === sortedBeatsheets[i + 1].id);
          const fromBeat = beats.find((b) => b.id === fromBeatsheet?.beat_id);
          const toBeat = beats.find((b) => b.id === toBeatsheet?.beat_id);
          const branch = branches.find((br) => br.id === branchId);

          edges.push({
            id: `beatsheet-${sortedBeatsheets[i].id}-${sortedBeatsheets[i + 1].id}`,
            source: `scene-${lastScene.id}`,
            target: `scene-${firstScene.id}`,
            type: "smoothstep",
            data: {
              fromBeatsheet: fromBeatsheet,
              toBeatsheet: toBeatsheet,
              fromBeat: fromBeat,
              toBeat: toBeat,
              branch: branch,
              fromScene: lastScene,
              toScene: firstScene,
              isIntraBeatsheet: false,
            },
          });
        }
      }
    }
  });

  return edges;
};

const createMomentEdges = (
  transitions: Tables<"blueprint_moment_transitions">[],
  moments: Tables<"blueprint_moments">[],
): MomentEdge[] => {
  let workingTransitions = transitions;

  if (transitions.length === 0 && moments.length >= 2) {
    workingTransitions = [
      {
        blueprint_story_id: moments[0].blueprint_story_id,
        id: "fake-transition",
        current_moment_id: moments[0].id,
        next_moment_id: moments[1].id,
        condition: "",
        created_at: null,
        updated_at: null,
      },
    ];
  }

  return workingTransitions.map((transition) => {
    const strokeColor = "#000000";

    return {
      id: transition.id,
      type: "smoothstep",
      source: transition.current_moment_id,
      target: transition.next_moment_id,
      animated: false,
      label:
        transition.condition.length > 50
          ? transition.condition.substring(0, 50) + "..."
          : transition.condition,
      style: { stroke: strokeColor },
      labelStyle: { fontSize: "12px" },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
        color: strokeColor,
      },
    };
  });
};

export const useStoryFlow = (options: StoryFlowData) => {
  // Scene level state
  const [sceneNodes, setSceneNodes, onSceneNodesChange] = useNodesState<SceneNode>([]);
  const [sceneEdges, setSceneEdges, onSceneEdgesChange] = useEdgesState<SceneEdge>([]);
  const [selectedSceneNode, setSelectedSceneNode] = useState<SceneNode | null>(null);

  // Moment level state
  const [momentNodes, setMomentNodes, onMomentNodesChange] = useNodesState<MomentNode>([]);
  const [momentEdges, setMomentEdges, onMomentEdgesChange] = useEdgesState<MomentEdge>([]);
  const [selectedMomentNode, setSelectedMomentNode] = useState<MomentNode | null>(null);

  const { performLayout } = useFlowLayout();
  const { screenToFlowPosition } = useReactFlow();

  // Initialize both graphs
  useEffect(() => {
    const initialSceneNodes = createSceneNodes(
      options.scenes,
      options.beatsheets,
      options.beats,
      options.branches,
    );
    const initialSceneEdges = createSceneEdges(
      options.scenes,
      options.beatsheets,
      options.beats,
      options.branches,
    );
    const layoutedSceneNodes = performLayout(initialSceneNodes, initialSceneEdges);
    setSceneNodes(layoutedSceneNodes);
    setSceneEdges(initialSceneEdges);

    // Moment level initialization
    const initialMomentNodes = createMomentNodes(options.moments);
    const initialMomentEdges = createMomentEdges(options.transitions, options.moments);
    const layoutedMomentNodes = performLayout(initialMomentNodes, initialMomentEdges);
    setMomentNodes(layoutedMomentNodes);
    setMomentEdges(initialMomentEdges);
  }, [
    options.moments,
    options.transitions,
    options.scenes,
    options.beatsheets,
    options.beats,
    options.branches,
  ]);

  // Scene level connection handling
  const {
    onConnect: onSceneConnect,
    findNodeAtPosition: findSceneAtPosition,
    handleNodeInsertion: handleSceneInsertion,
  } = useConnectionHandling({
    nodes: sceneNodes,
    edges: sceneEdges,
    setEdges: setSceneEdges,
  });

  // Moment level connection handling
  const {
    onConnect: onMomentConnect,
    findNodeAtPosition: findMomentAtPosition,
    handleNodeInsertion: handleMomentInsertion,
  } = useConnectionHandling({
    nodes: momentNodes,
    edges: momentEdges,
    setEdges: setMomentEdges,
  });

  const onScenesDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const type = event.dataTransfer.getData("application/reactflow") as SceneType;
      if (!Object.keys(SCENE_NODE_TYPES).includes(type)) return;

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNodeId = `${type}-${sceneNodes.length + 1}`;
      const newNode: SceneNode = {
        id: newNodeId,
        type,
        position,
        data: {
          label: `New ${type}`,
          type,
          isInitialized: false,
          sceneOrder: sceneNodes.length + 1,
          beatsheet: null,
          beat: null,
          branch: null,
          scene: null,
        },
      };

      const targetNode = findSceneAtPosition(position);
      const newEdges = handleSceneInsertion(targetNode, newNode.id);
      const updatedNodes = [...sceneNodes, newNode];
      const layoutedNodes = performLayout(updatedNodes, newEdges) as SceneNode[];

      setSceneNodes(layoutedNodes);
      setSceneEdges(newEdges as SceneEdge[]);
    },
    [sceneNodes, screenToFlowPosition, findSceneAtPosition, handleSceneInsertion, performLayout],
  );

  const onMomentsDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const type = event.dataTransfer.getData("application/reactflow") as MomentType;
      if (!Object.keys(MOMENT_NODE_TYPES).includes(type)) return;

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNodeId = `${type}-${momentNodes.length + 1}`;
      const borderColor = getRandomTailwindColor(newNodeId);
      momentColorMap.set(newNodeId, borderColor);

      const newNode: MomentNode = {
        id: newNodeId,
        type, // Already cast as MomentType above
        position,
        data: {
          label: `New ${type}`,
          type, // Use the same type from above
          isInitialized: false,
          sceneId: null,
          content: null,
          conditions: [],
          triggers: [],
          borderColor, // Use the generated borderColor
        },
      };

      const targetNode = findMomentAtPosition(position);
      const newEdges = handleMomentInsertion(targetNode, newNode.id);
      const updatedNodes = [...momentNodes, newNode];
      const layoutedNodes = performLayout(updatedNodes, newEdges) as MomentNode[];

      setMomentNodes(layoutedNodes);
      setMomentEdges(newEdges as MomentEdge[]);
    },
    [momentNodes, screenToFlowPosition, findMomentAtPosition, handleMomentInsertion, performLayout],
  );

  const onScenesLayout = useCallback(() => {
    const layoutedNodes = performLayout(sceneNodes, sceneEdges);
    setSceneNodes(layoutedNodes);
  }, [sceneNodes, sceneEdges, performLayout]);

  const onFlowLayout = useCallback(() => {
    const layoutedNodes = performLayout(momentNodes, momentEdges);
    setMomentNodes(layoutedNodes);
  }, [momentNodes, momentEdges, performLayout]);

  const updateSceneNode = useCallback(
    (nodeId: string, newData: Partial<SceneNode>) => {
      setSceneNodes((nds) =>
        nds.map((node) => (node.id === nodeId ? ({ ...node, ...newData } as SceneNode) : node)),
      );
    },
    [setSceneNodes],
  );

  const updateMomentNode = useCallback(
    (nodeId: string, newData: Partial<MomentNode>) => {
      setMomentNodes((nds) =>
        nds.map((node) => (node.id === nodeId ? ({ ...node, ...newData } as MomentNode) : node)),
      );
    },
    [setMomentNodes],
  );

  return {
    onFlowLayout,

    // Scene level
    sceneNodes,
    sceneEdges,
    selectedSceneNode,
    setSelectedSceneNode,
    onSceneNodesChange,
    onSceneEdgesChange,
    onSceneConnect,
    onScenesDrop,
    onScenesLayout,
    updateSceneNode,

    // Moment level
    momentNodes,
    momentEdges,
    selectedMomentNode,
    setSelectedMomentNode,
    onMomentNodesChange,
    onMomentEdgesChange,
    onMomentConnect,
    onMomentsDrop,

    updateMomentNode,
  };
};
