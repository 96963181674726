import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tables, TablesInsert } from "@/types/database.ts";
import { supabase } from "@/vendor/supabaseClient.ts";
import SpokableListBox from "../../components/admin/SpokableListBox.tsx";
import {
  IMAGE_BUCKET_NAME,
  LOCALE_FRENCH,
  MOMENT_TYPES,
  SOUND_BUCKET_NAME,
} from "@/constants/constant.ts";
import StorageFileSelector from "../../components/admin/StorageFileSelector.tsx";
import AutoResizeTextArea from "../../components/admin/AutoResizeTextArea.tsx";
import DatabaseSelectInput from "../../components/admin/DatabaseSelectInput.tsx";
import { useCreatePlayerStoriesAndMoments } from "../../states/storyState.tsx";
import { DEV_VITE_URL } from "@/vendor/config.ts";
import FormField from "../../components/admin/FormField.tsx";
import { Checkbox, CheckboxField } from "../../components/catalyst/checkbox.tsx";
import { Description, Label } from "../../components/catalyst/fieldset.tsx";
import { Card, CardContent, CardH1 } from "../../components/admin/Card.tsx";
import { getSupabaseImageUrl, moveCharacterImageToMomentImage } from "@/utils/mediaUtil.ts";
import { Input } from "../../components/catalyst/input.tsx";
import StorageFileAudioPlayer from "../../components/admin/StorageFileAudioPlayer.tsx";
import NumericStepper from "../../components/admin/NumericStepper.tsx";
import { PromptManagementSideNavLayout } from "../../components/admin/PromptManagementSideNavLayout.tsx";
import { capitalize } from "@/utils/stringUtil.ts";
import useBackgroundMusicManager from "../../hooks/useBackgroundMusicManager.ts";
import {
  useCharacterImageFetcher,
  useFetchAllMomentData,
  useFetchMoments,
  useFetchStory,
  useMomentImageFetcher,
} from "@/hooks/database/useMoment.ts";
import { SpokableButton } from "../../components/admin/SpokableButton.tsx";
import CharacterImageCard from "../../components/admin/CharacterImageCard.tsx";
import MomentImageCard from "../../components/admin/MomentImageCard.tsx";
import SoundGeneratorButton from "../../components/admin/SoundGeneratorButton.tsx";
import { getEnvironment } from "@/utils/envUtil.ts";
import MediaGeneratorMomentModalButton from "@/components/admin/MediaGeneratorMomentModalButton.tsx";

interface MomentDetailProps {
  momentId?: string | null;
  onMomentUpdate?: (updatedMoment: Tables<"blueprint_moments">) => void;
}

function MomentDetail({ momentId: propsMomentId, onMomentUpdate }: MomentDetailProps) {
  const { momentId: paramsMomentId } = useParams<{ momentId: string }>();
  const navigate = useNavigate();
  const { stopBackgroundMusic } = useBackgroundMusicManager();
  const [isDirty, setIsDirty] = useState(false);
  const [moment, setMoment] = useState<Tables<"blueprint_moments"> | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const createStoryAndMoment = useCreatePlayerStoriesAndMoments();
  const momentId = propsMomentId || paramsMomentId;
  const [characterImages, setCharacterImages] = useState<
    Tables<"blueprint_character_medias">[] | null
  >(null);
  const [momentImages, setMomentImages] = useState<Tables<"blueprint_moment_medias">[] | null>(
    null,
  );

  const { fetchAllMomentData, ...allMomentData } = useFetchAllMomentData();
  const { fetchMoments } = useFetchMoments();
  const { fetchCharacterImages } = useCharacterImageFetcher();
  const { fetchMomentImages } = useMomentImageFetcher();
  const { fetchStory, story } = useFetchStory();

  useEffect(() => {
    fetchStory(moment?.blueprint_story_id);
    retrieveMomentImages();
  }, [moment]);

  useEffect(() => {
    if (momentId) {
      stopBackgroundMusic();
      fetchAllMomentData(momentId);
    }
  }, [momentId]);

  useEffect(() => {
    setMoment(allMomentData.momentData.moment);
    if (allMomentData.momentData.moment?.blueprint_story_id) {
      fetchMoments(allMomentData.momentData.moment.blueprint_story_id);
    }
  }, [allMomentData.momentData.moment]);

  useEffect(() => {
    if (!allMomentData.momentData.moment?.blueprint_character_id) return;
    retrieveCharacterImage(allMomentData.momentData.moment?.blueprint_character_id);
  }, [allMomentData.momentData.moment?.blueprint_character_id]);

  async function retrieveCharacterImage(characterId: string) {
    const images = await fetchCharacterImages(characterId);
    setCharacterImages(images);
  }

  async function retrieveMomentImages() {
    if (!moment) return;
    const images = await fetchMomentImages(moment.id);
    setMomentImages(images);
  }

  const isFullPageAndNotASubComponent = (): boolean => {
    return !propsMomentId;
  };

  function reload() {
    if (momentId) fetchAllMomentData(momentId);
  }

  async function updateMoment() {
    if (!moment) return;
    if (momentId == undefined) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...momentWithoutId } = moment;
    const { error } = await supabase
      .from("blueprint_moments")
      .update(momentWithoutId)
      .eq("id", momentId)
      .select();

    if (error) {
      setErrorMessage("Error updating moment: " + error.message);
    } else {
      if (onMomentUpdate) onMomentUpdate(moment);
      setErrorMessage("Saved!");
      setIsDirty(false);
      reload();
    }
  }

  async function createCharacter() {
    if (!moment) return;
    const { data: voice, error: voiceError } = await supabase
      .from("blueprint_voices")
      .select("*")
      .eq("environment", getEnvironment())
      .limit(1)
      .single();

    if (!voice) return;

    const characterData: TablesInsert<"blueprint_characters"> = {
      name: "New character",
      voice_id: voice.id,
      blueprint_story_id: moment?.blueprint_story_id,
    };

    const { data, error } = await supabase
      .from("blueprint_characters")
      .insert(characterData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error updating character: " + error.message);
    } else if (voiceError) {
      setErrorMessage("Error updating character: " + voiceError);
    } else {
      console.log(data);
      const createdCharacter = data as Tables<"blueprint_characters">;
      handleNavigation(`/admin/characters/${createdCharacter.id}`);
    }
  }

  const handleCancel = () => {
    if (!moment) return;
    handleNavigation(`/admin/stories/${moment.blueprint_story_id}`);
  };

  const handleTestMoment = async () => {
    if (!moment) return;
    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (user) {
      await createStoryAndMoment(
        {
          user_id: user.id,
          blueprint_story_id: moment.blueprint_story_id,
          language: LOCALE_FRENCH,
        },
        moment?.id,
      );
      let url;
      try {
        url = process.env.VITE_URL;
      } catch (e) {
        url = DEV_VITE_URL;
      }
      window.open(url + `launch/${moment.blueprint_story_id}`, "_blank", "noopener,noreferrer");
    }
  };
  const handleNavigation = (url: string) => {
    if (isDirty) {
      const userConfirmed = window.confirm(
        "You have unsaved changes. Are you sure you want to navigate away?",
      );
      if (!userConfirmed) {
        return;
      }
    }
    navigate(url);
  };

  async function createMomentImageFromExisting(fileName: string) {
    if (!moment) return;

    moveCharacterImageToMomentImage(fileName, moment.blueprint_story_id);

    const imageData: TablesInsert<"blueprint_moment_medias"> = {
      media_url: fileName,
      blueprint_moment_id: moment.id,
      blueprint_story_id: moment?.blueprint_story_id,
    };

    const { error } = await supabase.from("blueprint_moment_medias").insert(imageData);

    if (error) {
      setErrorMessage("Error creating Moment images: " + error.message);
    } else {
      retrieveMomentImages();
    }
  }

  const handleContextCheckboxChange = async (contextId: string, isChecked: boolean) => {
    console.log(contextId, isChecked);
    if (!moment) return;
    if (isChecked) {
      const contextData: TablesInsert<"blueprint_moment_context_links"> = {
        moment_id: moment?.id,
        context_block_id: contextId,
      };

      const { error } = await supabase
        .from("blueprint_moment_context_links")
        .upsert(contextData)
        .select()
        .single();

      if (error) {
        setErrorMessage("Error creating context link: " + error.message);
      }
    } else {
      const { error } = await supabase
        .from("blueprint_moment_context_links")
        .delete()
        .eq("context_block_id", contextId)
        .eq("moment_id", moment.id);
      if (error) {
        setErrorMessage("Error deleting context link: " + error.message);
      }
    }
    reload();
  };

  if (!moment || !story) {
    return <div>Loading...</div>;
  }

  return (
    <PromptManagementSideNavLayout showSidePanel={isFullPageAndNotASubComponent()}>
      <div className="max-w-2xl">
        <div className="top-section top-0 backdrop-blur-xl z-10 p-6">
          {errorMessage && (
            <div
              className="rounded border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
              onClick={() => setErrorMessage(null)}
            >
              {errorMessage}
            </div>
          )}
          <div className="flex gap-6 items-stretch">
            {(allMomentData.momentData.backgroundImages.length > 0 ||
              allMomentData.momentData.characterImages.length > 0) && (
              <div className="flex flex-col justify-between">
                {momentImages &&
                  momentImages
                    .slice(0, 1)
                    .map((image) => (
                      <img
                        key={image.id}
                        alt="Moment Image"
                        className={`object-cover ${
                          momentImages.length === 0 ? "w-64 h-full" : "w-24 h-1/2"
                        }`}
                        src={
                          getSupabaseImageUrl(moment.blueprint_story_id, image.media_url) ||
                          `https://api.dicebear.com/9.x/shapes/svg?seed=${momentId}`
                        }
                      />
                    ))}
                {characterImages &&
                  characterImages
                    .slice(0, 1)
                    .map((image) => (
                      <img
                        alt="Character Image"
                        key={image.id}
                        className={`object-cover ${
                          characterImages.length === 0 ? "w-64 h-full" : "w-24 h-1/2"
                        }`}
                        src={
                          getSupabaseImageUrl(moment.blueprint_story_id, image.media_url) ||
                          `https://api.dicebear.com/9.x/lorelei-neutral/svg?seed=${momentId}`
                        }
                      />
                    ))}
              </div>
            )}
            <div className="flex flex-col justify-between w-full">
              <div>
                <div className="text-sm text-gray-500 mb-2">Moment</div>
                <h1 className="text-5xl font-bold mb-2">{capitalize(moment.moment_name)}</h1>
                <div className="text-xl mb-4">{capitalize(moment.moment_type)}</div>
              </div>
              <div className="flex gap-2">
                <SpokableButton onClick={handleTestMoment}>Test Moment</SpokableButton>
                <SpokableButton onClick={updateMoment}>Save</SpokableButton>
                {isFullPageAndNotASubComponent() && (
                  <SpokableButton onClick={handleCancel}>Back</SpokableButton>
                )}
              </div>
            </div>
          </div>
        </div>

        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Basic" id="basic" />

            <FormField label="Moment Name">
              <Input
                type="text"
                id="moment_name"
                value={capitalize(moment.moment_name) || ""}
                onChange={(e) => {
                  setMoment({ ...moment, moment_name: e.target.value });
                  setIsDirty(true);
                }}
              />
            </FormField>

            <FormField label="Moment Setting">
              <AutoResizeTextArea
                value={moment.moment_setting || ""}
                onChange={(e) => {
                  setMoment({ ...moment, moment_setting: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Moment Type">
              <SpokableListBox
                options={MOMENT_TYPES}
                value={
                  MOMENT_TYPES.find((type) => type.key === moment.moment_type) || MOMENT_TYPES[0]
                }
                onChange={(selectedType) => {
                  setMoment({ ...moment, moment_type: selectedType.key });
                  setIsDirty(true);
                }}
              />
            </FormField>

            <FormField label="Belong to scene">
              <DatabaseSelectInput
                table="blueprint_scenes"
                keyColumn="id"
                labelColumn="name"
                value={moment.scene_id || ""}
                onChange={(value) => {
                  setMoment({ ...moment, scene_id: value });
                  setIsDirty(true);
                }}
                placeholder="Select a scene"
                addNullValueOption={false}
                storyId={moment.blueprint_story_id}
                storyIdColumn={"story_id"}
              />
            </FormField>
          </CardContent>
        </Card>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Character" id="character" />

            <FormField>
              <DatabaseSelectInput
                table="blueprint_characters"
                keyColumn="id"
                labelColumn="name"
                storyId={moment.blueprint_story_id}
                value={moment.blueprint_character_id || ""}
                onChange={(value) => {
                  setMoment({ ...moment, blueprint_character_id: value || "" });
                  if (value) retrieveCharacterImage(value);
                  setIsDirty(true);
                }}
                placeholder="Select a character"
              />

              <div className="mt-6">
                <SpokableButton color={"light"} onClick={createCharacter}>
                  Create New Character
                </SpokableButton>
                {moment.blueprint_character_id && (
                  <SpokableButton
                    className="ml-6"
                    onClick={() => {
                      handleNavigation(`/admin/characters/${moment?.blueprint_character_id}`);
                    }}
                  >
                    Edit
                  </SpokableButton>
                )}
              </div>
            </FormField>
            <FormField>
              <ul>
                {characterImages &&
                  characterImages.map((image) => {
                    return (
                      <li key={image.id} className="mb-8">
                        <CharacterImageCard
                          storyId={moment.blueprint_story_id}
                          image={image}
                          hideDelete={true}
                          whiteBackground={true}
                        />
                      </li>
                    );
                  })}
              </ul>
            </FormField>

            <FormField label="Starting Dialogue">
              <AutoResizeTextArea
                value={moment.starting_dialogue || ""}
                onChange={(e) => {
                  setMoment({ ...moment, starting_dialogue: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>
          </CardContent>
        </Card>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Sound" id="sound" />
            <FormField label="Intro">
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <StorageFileSelector
                  bucketName={SOUND_BUCKET_NAME}
                  storyId={moment.blueprint_story_id}
                  onStorageFileSelected={(storageFileUrl) => {
                    setMoment({ ...moment, sound_intro: storageFileUrl });
                    setIsDirty(true);
                  }}
                  value={moment.sound_intro}
                />
                <SoundGeneratorButton
                  storyId={story.id}
                  onUploadComplete={(storageFileUrl) => {
                    setMoment({ ...moment, sound_intro: storageFileUrl });
                    setIsDirty(true);
                  }}
                  prompt={moment.prompt_sound_intro_generation}
                />
              </div>
              {moment.sound_intro && (
                <>
                  <StorageFileAudioPlayer
                    bucketName={SOUND_BUCKET_NAME}
                    storyId={moment.blueprint_story_id}
                    fileName={moment.sound_intro}
                  />
                </>
              )}
            </FormField>

            <FormField label="Background">
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <StorageFileSelector
                  bucketName={SOUND_BUCKET_NAME}
                  storyId={moment.blueprint_story_id}
                  onStorageFileSelected={(storageFileUrl) => {
                    setMoment({ ...moment, sound_outro: storageFileUrl });
                    setIsDirty(true);
                  }}
                  value={moment.sound_outro}
                />
                <SoundGeneratorButton
                  storyId={story.id}
                  onUploadComplete={(storageFileUrl) => {
                    setMoment({ ...moment, sound_outro: storageFileUrl });
                    setIsDirty(true);
                  }}
                  prompt={moment.prompt_sound_outro_generation}
                />
              </div>
              {moment.sound_outro && (
                <StorageFileAudioPlayer
                  bucketName={SOUND_BUCKET_NAME}
                  storyId={moment.blueprint_story_id}
                  fileName={moment.sound_outro}
                />
              )}
            </FormField>
            <div className={`w-full ${isFullPageAndNotASubComponent() ? "lg:w-1/3" : ""} `}>
              <div className="flex justify-between">
                <FormField label="Sound Volume">
                  <NumericStepper
                    value={moment.sound_intro_volume}
                    onChange={(e) => {
                      setMoment({ ...moment, sound_intro_volume: e });
                      setIsDirty(true);
                    }}
                    label={""}
                  />
                </FormField>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Visuals" id="image" />

            <FormField>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <StorageFileSelector
                  bucketName={IMAGE_BUCKET_NAME}
                  storyId={story.id}
                  onStorageFileSelected={(storageFileUrl) =>
                    createMomentImageFromExisting(storageFileUrl)
                  }
                  value={story.story_logo_image}
                  hideImagePreview={true}
                  useSimpleSelect={true}
                  showUploader={false}
                />
                <MediaGeneratorMomentModalButton
                  story={story}
                  onUploadComplete={() => retrieveMomentImages()}
                  basePrompt={moment.prompt_image_generation}
                  promptModifier={story.image_prompt_style}
                  baseFileName={moment.moment_name}
                  momentId={moment.id}
                  cinematicPrompt={moment.prompt_background_video_generation}
                  eventPrompt={moment.prompt_event_video_generation}
                  imageReferenceFileName={
                    momentImages
                      ? momentImages[Math.floor(Math.random() * momentImages.length)]?.media_url
                      : story.background_image || null
                  }
                />
              </div>
            </FormField>

            {momentImages && momentImages.length > 0 && (
              <FormField label="Selected Images:">
                <ul>
                  {momentImages.map((image) => {
                    return (
                      <li key={image.id}>
                        <MomentImageCard
                          storyId={moment.blueprint_story_id}
                          image={image}
                          whiteBackground={true}
                          onRefreshNeeded={retrieveMomentImages}
                        />
                      </li>
                    );
                  })}
                </ul>
              </FormField>
            )}
          </CardContent>
        </Card>
        {allMomentData.momentData.contextBlocks.length > 0 && (
          <Card className="pb-20" isFullWidth={true}>
            <CardContent>
              <CardH1 label="Context" id="context" />
              <ul className="grid grid-cols-2 gap-4">
                {allMomentData.momentData.contextBlocks.map((context) => {
                  const isLinked = allMomentData.momentData.contextBlockLinks.some(
                    (link) => link.context_block_id === context.id,
                  );

                  return (
                    <li key={context.id}>
                      <CheckboxField>
                        <Checkbox
                          name="discoverability"
                          value="show_on_events_page"
                          checked={isLinked}
                          onChange={(isChecked) =>
                            handleContextCheckboxChange(context.id, isChecked)
                          }
                        />
                        <Label>
                          {context.context_block_name}{" "}
                          <Link to={`/admin/contexts/${story.id}/${context.id}`}>(edit)</Link>
                        </Label>
                        <Description className="overflow-hidden text-ellipsis whitespace-nowrap">
                          {context.context_block_description}
                        </Description>
                      </CheckboxField>
                    </li>
                  );
                })}
              </ul>
            </CardContent>
          </Card>
        )}
      </div>
    </PromptManagementSideNavLayout>
  );
}

export default MomentDetail;
