import { useCallback, useRef, useState } from "react";
import axios from "axios";
import { DEV_FAST_API_HTTP } from "@/vendor/config.ts";
import { CharacterImageOutput, OnePager } from "@/types/fastApiTypes.ts";
import { RankedIdeas } from "@/types/ideas_prompts_generated_types.ts";
import { LogLine } from "@/types/logline_prompts_generated_types.ts";
import { CharactersSummary } from "@/types/character_prompts_generated_types.ts";
import {
  LearningConceptOutput,
  SyllabusOutput,
} from "@/types/learning_concept_prompts_generated_types.ts";

// Define a type for the API response
type ApiResponse<T> = {
  data: T;
  status: string;
  callApi: (payload?: any) => Promise<void>;
  setData: React.Dispatch<React.SetStateAction<T | null>>;
};

export const useApiCall = <T>(endpoint: string, method: "GET" | "POST" = "POST") => {
  const [data, setData] = useState<T | null>(null);
  const [status, setStatus] = useState("");
  const isLoadingRef = useRef(false);

  const callApi = useCallback(
    async (payload?: any) => {
      if (isLoadingRef.current) {
        console.log(`API call to ${endpoint} already in progress. Skipping.`);
        return;
      }
      const URL = import.meta.env.VITE_FAST_API_HTTP || DEV_FAST_API_HTTP;
      try {
        setStatus(`Processing...`);
        isLoadingRef.current = true;
        const response = await axios({
          method,
          url: `${URL}${endpoint}`,
          data: payload,
          headers: {
            "Content-Type": method === "POST" ? "application/json" : undefined,
          },
        });
        setStatus("");
        setData(response.data);
      } catch (error) {
        setStatus(`Error in ${endpoint}: ${error}`);
        alert(`Error in ${endpoint}: ${error}`);
      } finally {
        isLoadingRef.current = false;
      }
    },
    [endpoint, method],
  );

  return { data, status, callApi, setData };
};

export const useGenerateLearningConcept = () =>
  useApiCall<LearningConceptOutput>("generate_learning_concepts");

export const useGenerateSyllabus = () => useApiCall<SyllabusOutput>("generate_syllabus");
export const useGenerateIdeas = () => useApiCall<RankedIdeas>("generate_ideas");
export const useGenerateLogLine = () => useApiCall<LogLine>("generate_logline");
export const useGenerateOnePager = () => useApiCall<OnePager>("generate_onepager");
export const useGenerateCharactersSummary = () =>
  useApiCall<CharactersSummary>("generate_characters_summary");
export const useGenerateCharacterImages = () =>
  useApiCall<CharacterImageOutput>("generate_character_images");

// Export types if needed
export type { ApiResponse };
