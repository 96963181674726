import React from "react";
import { StoryGraphContainer } from "@/components/admin/storyGraph/StoryGraphContainer.tsx";
import { GlobalSideNavLayout } from "@/components/admin/GlobalSideNavLayout.tsx";
import { useParams } from "react-router-dom";
import { PAGE_FLOW } from "@/constants/constant.ts";

const StoryGraph: React.FC = () => {
  const { storyId } = useParams();

  if (!storyId) return;
  return (
    <GlobalSideNavLayout activePageId={PAGE_FLOW} padding={"p-0"} storyId={storyId}>
      <StoryGraphContainer storyId={storyId} />
    </GlobalSideNavLayout>
  );
};

export default StoryGraph;
