import { Sheet, SheetContent } from "@/components/ui/sheet.tsx";
import { StoryGraphNode } from "@/components/admin/storyGraph/common/types.ts";

interface PropertyPanelProps {
  selectedNode: StoryGraphNode | null;
  onClose: () => void;
  children?: React.ReactNode;
}

const PropertyPanel = ({ selectedNode, onClose, children }: PropertyPanelProps) => {
  const isInitialized = !!selectedNode && selectedNode.data.isInitialized;

  return (
    <Sheet open={isInitialized} onOpenChange={onClose}>
      <SheetContent side="right" className="w-1/3 sm:max-w-none flex flex-col h-full ">
        {selectedNode && (
          <div className="flex flex-col h-full p-6">
            <div className="flex-1 overflow-y-auto">{children}</div>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
};

export default PropertyPanel;
