import React, { useState } from "react";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { Tables, TablesInsert } from "../../../types/database.ts";
import { DialogTitle } from "../../catalyst/dialog.tsx";
import { SpokableButton } from "../SpokableButton.tsx";
import { SceneWithoutInteractivityOutput } from "@/types/scene_prompts_generated_types.ts";

export interface SaveAllProps {
  story: Tables<"blueprint_stories"> | null;
  clickedBranch: Tables<"blueprint_branches"> | undefined | null;
  selectedBeatsheet: TablesInsert<"blueprint_beatsheets"> | null;
  handleClose: () => void;
  generatedScene: SceneWithoutInteractivityOutput | null;
  previousScenes: Tables<"blueprint_scenes">[] | null;
}

const SaveAll: React.FC<SaveAllProps> = ({
  generatedScene,
  story,
  clickedBranch,
  selectedBeatsheet,
  handleClose,
  previousScenes,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const saveAll = async () => {
    if (!story || !selectedBeatsheet || !clickedBranch) return;

    try {
      const beatsheet = await handleBeatsheet();
      if (beatsheet) {
        await createScene(beatsheet);
        handleClose();
      }
    } catch (error) {
      setErrorMessage("Error saving: " + (error as Error).message);
    }
  };

  async function handleBeatsheet() {
    if (!selectedBeatsheet || !story || !clickedBranch) return null;

    // First try to find existing beatsheet
    const { data: existingBeatsheet, error: searchError } = await supabase
      .from("blueprint_beatsheets")
      .select()
      .eq("branch_id", clickedBranch.id)
      .eq("story_id", story.id)
      .eq("beat_id", selectedBeatsheet.beat_id)
      .limit(1)
      .single();

    if (searchError) {
      if (!existingBeatsheet) return null;

      setErrorMessage("Error searching for beatsheet: " + searchError);
      return null;
    }

    // If beatsheet exists, update it
    if (existingBeatsheet) {
      const { data: updatedBeatsheet, error: updateError } = await supabase
        .from("blueprint_beatsheets")
        .update(selectedBeatsheet)
        .eq("id", existingBeatsheet.id)
        .select()
        .single();

      if (updateError) {
        setErrorMessage("Error updating beatsheet: " + updateError.message);
        return null;
      }

      return updatedBeatsheet;
    }

    // If no existing beatsheet, create new one
    const { data: newBeatsheet, error: insertError } = await supabase
      .from("blueprint_beatsheets")
      .insert(selectedBeatsheet)
      .select()
      .single();

    if (insertError) {
      setErrorMessage("Error creating new beatsheet: " + insertError.message);
      return null;
    }

    return newBeatsheet;
  }

  async function createScene(beatsheet: Tables<"blueprint_beatsheets">) {
    if (!clickedBranch || !generatedScene || !story) return;

    const sceneData: TablesInsert<"blueprint_scenes"> = {
      beatsheet_id: beatsheet.id,
      branch_id: clickedBranch.id,
      character_development_contribution: generatedScene.character_development_contribution,
      conflict_resolution: generatedScene.conflict_resolution,
      main_conflict: generatedScene.main_conflict,
      name: generatedScene.name,
      //objectives: generatedScene.objectives,
      characters: generatedScene.characters,
      plot_contribution: generatedScene.plot_contribution,
      //possible_branches: generatedScene.possible_branches,
      scene_date: generatedScene.scene_date,
      scene_location: generatedScene.scene_location,
      scene_order: previousScenes?.length
        ? (previousScenes[previousScenes.length - 1]?.scene_order ?? -1) + 1
        : 0,
      scene_summary: generatedScene.scene_summary,
      story_id: story?.id,
      // traps: generatedScene.traps,
      // wining_flow: generatedScene.wining_flow,
    };

    const { data, error } = await supabase
      .from("blueprint_scenes")
      .insert(sceneData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error inserting scene: " + error.message);
    } else {
      setErrorMessage("Saved successfully!");
      return data;
    }
  }

  return (
    <>
      <DialogTitle as="h2" className="font-serif py-6 text-4xl font-bold mb-4">
        Complete process
      </DialogTitle>
      <div className="flex">
        <div className="flex-grow">
          {errorMessage && <div>{errorMessage}</div>}
          <SpokableButton onClick={saveAll} className="mt-6 mb-6">
            Save all
          </SpokableButton>
        </div>
      </div>
    </>
  );
};

export default SaveAll;
