import { useState } from "react";
import { useRecoilState } from "recoil";
import { modalStoryGenerateState } from "@/states/ModalState.ts";
import {
  useGenerateCharactersSummary,
  useGenerateIdeas,
  useGenerateLearningConcept,
  useGenerateLogLine,
  useGenerateOnePager,
  useGenerateSyllabus,
} from "./useGenerateHooks.ts";

export const useGenerateSteps = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalStoryGenerateState);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [selectedIdea, setSelectedIdea] = useState<string | null>(null);
  const [selectedLearningConcept, setSelectedLearningConcept] = useState<string | null>(null);

  const {
    data: learningConcepts,
    status: learningConceptStatus,
    callApi: generateLearningConcept,
    setData: setLearningConcept,
  } = useGenerateLearningConcept();

  const {
    data: syllabus,
    status: syllabusStatus,
    callApi: generateSyllabus,
    setData: setSyllabus,
  } = useGenerateSyllabus();

  const {
    data: ideas,
    status: ideasStatus,
    callApi: generateIdeas,
    setData: setIdeas,
  } = useGenerateIdeas();

  const {
    data: logLine,
    status: logLineStatus,
    callApi: generateLogLine,
    setData: setLogLine,
  } = useGenerateLogLine();

  const {
    data: onePager,
    status: onePagerStatus,
    callApi: generateOnePager,
    setData: setOnePager,
  } = useGenerateOnePager();

  const {
    data: charactersSummary,
    status: charactersSummaryStatus,
    callApi: generateCharactersSummary,
    setData: setCharactersSummary,
  } = useGenerateCharactersSummary();

  const handleClose = () => {
    setIsOpen(false);
    setIdeas(null);
    setStepIndex(0);
    setLogLine(null);
    setOnePager(null);
    setCharactersSummary(null);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    learningConcepts,
    learningConceptStatus,
    generateLearningConcept,
    setLearningConcept,
    selectedLearningConcept,
    setSelectedLearningConcept,
    selectedIdea,
    setSelectedIdea,
    syllabus,
    syllabusStatus,
    generateSyllabus,
    setSyllabus,
    ideas,
    ideasStatus,
    generateIdeas,
    setIdeas,
    logLine,
    logLineStatus,
    generateLogLine,
    setLogLine,
    onePager,
    onePagerStatus,
    generateOnePager,
    setOnePager,
    charactersSummary,
    charactersSummaryStatus,
    generateCharactersSummary,
    setCharactersSummary,
    handleClose,
  };
};
