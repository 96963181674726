import React from "react";
import { NodeProps } from "@xyflow/react";
import { Square } from "lucide-react";
import { BaseNode } from "@/components/admin/storyGraph/common/BaseNode.tsx";
import { SceneNode } from "@/components/admin/storyGraph/common/types.ts";

const SceneBaseNode: React.FC<NodeProps<SceneNode>> = (props) => {
  return (
    <BaseNode {...props}>
      <div className="flex items-center gap-2">
        <Square className="w-5 h-5 " />
        <span>{props.data.label}</span>
      </div>
    </BaseNode>
  );
};

export default SceneBaseNode;
