import { useCallback } from "react";
import dagre from "dagre";
import { Edge, Node } from "@xyflow/react";

export const useFlowLayout = () => {
  const performLayout = useCallback(
    <T extends Node>(currentNodes: T[], currentEdges: Edge[]): T[] => {
      const dagreGraph = new dagre.graphlib.Graph();
      dagreGraph.setDefaultEdgeLabel(() => ({}));
      dagreGraph.setGraph({ rankdir: "LR", ranksep: 300, nodesep: 150 });

      currentNodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: 160, height: 60 });
      });

      currentEdges.forEach((edge) => {
        if (edge.source && edge.target) {
          dagreGraph.setEdge(edge.source, edge.target);
        }
      });

      dagre.layout(dagreGraph);

      return currentNodes.map((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        return {
          ...node,
          position: {
            x: nodeWithPosition.x - 80,
            y: nodeWithPosition.y - 30,
          },
        } as T;
      });
    },
    [],
  );

  return { performLayout };
};
