import { Edge, Node, NodeTypes } from "@xyflow/react";
import { Tables } from "@/types/database";
import BonusNode from "@/components/admin/storyGraph/momentLevel/nodes/BonusNode.tsx";
import CraftingNode from "@/components/admin/storyGraph/momentLevel/nodes/CraftingNode.tsx";
import MonologueNode from "@/components/admin/storyGraph/momentLevel/nodes/MonologueNode.tsx";
import TeachingNode from "@/components/admin/storyGraph/momentLevel/nodes/TeachingNode.tsx";
import TestingNode from "@/components/admin/storyGraph/momentLevel/nodes/TestingNode.tsx";
import MonologueInstructNode from "@/components/admin/storyGraph/momentLevel/nodes/MonologueInstructNode.tsx";
import GateMomentNode from "@/components/admin/storyGraph/momentLevel/nodes/GateMomentNode.tsx";
import ObjectGenerationNode from "@/components/admin/storyGraph/momentLevel/nodes/ObjectGenerationNode.tsx";
import NexusNode from "@/components/admin/storyGraph/sceneLevel/nodes/NexusNode.tsx";
import GateNode from "@/components/admin/storyGraph/sceneLevel/nodes/GateNode.tsx";
import SceneBaseNode from "@/components/admin/storyGraph/sceneLevel/nodes/SceneBaseNode.tsx";

// Scene Types
export interface BaseNodeProps {
  isConnectable: boolean;
  borderColor?: string;
  children?: React.ReactNode;
}

export interface BaseNodeData extends Record<string, unknown> {
  label: string;
  type: SceneType | MomentType;
  isInitialized: boolean;
}

// Scene types
export type SceneType = "scene" | "gate" | "nexus";

export const SCENE = "scene";
export const GATE = "gate";
export const NEXUS = "nexus";

export interface SceneNodeData extends BaseNodeData {
  sceneOrder: number;
  beatsheet: Tables<"blueprint_beatsheets"> | null | undefined;
  beat: Tables<"blueprint_beats"> | null | undefined;
  branch: Tables<"blueprint_branches"> | null | undefined;
  scene: Tables<"blueprint_scenes"> | null | undefined;
}

export type SceneNode = Node<SceneNodeData, SceneType>;

export interface SceneEdgeData extends Record<string, unknown> {
  fromBeatsheet: Tables<"blueprint_beatsheets"> | null | undefined;
  toBeatsheet: Tables<"blueprint_beatsheets"> | null | undefined;
  fromBeat: Tables<"blueprint_beats"> | null | undefined;
  toBeat: Tables<"blueprint_beats"> | null | undefined;
  branch: Tables<"blueprint_branches"> | null | undefined;
  fromScene: Tables<"blueprint_scenes">;
  toScene: Tables<"blueprint_scenes">;
  isIntraBeatsheet: boolean;
}

export type SceneEdge = Edge<SceneEdgeData>;

// Moment types
export type MomentType =
  | "bonus"
  | "crafting"
  | "monologue"
  | "teaching"
  | "testing"
  | "monologueInstruct"
  | "gateMoment"
  | "objectGeneration";

export const BONUS = "bonus";
export const CRAFTING = "crafting";
export const MONOLOGUE = "monologue";
export const TEACHING = "teaching";
export const TESTING = "testing";
export const MONOLOGUE_INSTRUCT = "monologueInstruct";
export const GATE_MOMENT = "gateMoment";
export const OBJECT_GENERATION = "objectGeneration";

export interface MomentNodeData extends BaseNodeData {
  label: string;
  type: MomentType;
  isInitialized: boolean;
  sceneId: string | null;
  content: string | null | undefined;
  conditions: string[];
  triggers: string[];
  borderColor: string;
}

export type MomentNode = Node<MomentNodeData, MomentType>;

export interface MomentEdgeData extends Record<string, unknown> {
  condition: string;
  animated?: boolean;
  label?: string;
  style?: {
    stroke: string;
  };
  labelStyle?: {
    fontSize: string;
  };
}

export type MomentEdge = Edge<MomentEdgeData>;

// Graph types
export type StoryGraphNode = SceneNode | MomentNode;
export type StoryGraphEdge = SceneEdge | MomentEdge;

// Node Components
export const SCENE_NODE_TYPES = {
  [SCENE]: SceneBaseNode,
  [GATE]: GateNode,
  [NEXUS]: NexusNode,
} as const;

export const MOMENT_NODE_TYPES: NodeTypes = {
  [BONUS]: BonusNode,
  [CRAFTING]: CraftingNode,
  [MONOLOGUE]: MonologueNode,
  [TEACHING]: TeachingNode,
  [TESTING]: TestingNode,
  [MONOLOGUE_INSTRUCT]: MonologueInstructNode,
  [GATE_MOMENT]: GateMomentNode,
  [OBJECT_GENERATION]: ObjectGenerationNode,
};
