import React, { useEffect, useState } from "react";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { Tables, TablesInsert } from "@/types/database.ts";
import AutoResizeTextArea from "@/components/admin/AutoResizeTextArea.tsx";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import {
  SceneInput,
  SceneWithoutInteractivityOutput,
} from "@/types/scene_prompts_generated_types.ts";
import DynamicForm from "@/components/admin/DynamicForm.tsx";

interface GenerateSceneProps {
  story: Tables<"blueprint_stories"> | null;
  status: string;
  branch: Tables<"blueprint_branches"> | null | undefined;
  characters: Tables<"blueprint_characters">[];
  previousScenes: Tables<"blueprint_scenes">[] | null;
  previousBeatsheets: Tables<"blueprint_beatsheets">[] | null;
  selectedBeatsheet: TablesInsert<"blueprint_beatsheets"> | null;
  generateScene: (payload: any) => void;
  generatedScene: SceneWithoutInteractivityOutput | null;
  setGeneratedScene: React.Dispatch<React.SetStateAction<SceneWithoutInteractivityOutput | null>>;
}

const GenerateScene: React.FC<GenerateSceneProps> = ({
  story,
  status,
  branch,
  characters,
  previousScenes,
  selectedBeatsheet,
  generateScene,
  generatedScene,
  previousBeatsheets,
  setGeneratedScene,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [instructions, setInstructions] = useState("");

  // Automatically trigger generation if there's no scene yet
  useEffect(() => {
    if (!generatedScene && branch && story) {
      handleGenerate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reset loading state if status becomes empty
  useEffect(() => {
    if (status === "") {
      setIsGenerating(false);
    }
  }, [status]);

  const handleGenerate = () => {
    if (!branch || !story) return;
    setIsGenerating(true);

    const params: SceneInput = {
      one_pager: JSON.stringify(branch.one_pager),
      character_list: JSON.stringify(characters),
      audience: story.audience || "",
      beat_to_generate: selectedBeatsheet?.description || "",
      previous_scenes: JSON.stringify(previousScenes),
      previous_beatsheets: JSON.stringify(previousBeatsheets),
      seed: instructions,
    };

    generateScene(params);
  };

  const handleRegenerate = () => {
    // Clear out the old scene if you want to, or just generate anew
    setGeneratedScene(null);
    handleGenerate();
  };

  return (
    // The parent container is now a flex container that takes full height
    <div className="w-full max-w-4xl mx-auto p-6 max-h-[80vh]  flex flex-col">
      {/* Header / Title (non-scrolling) */}
      <div className="flex items-center justify-between mb-6 shrink-0">
        <h3 className="text-2xl font-bold">
          Scene Generator
          <span className="text-sm ml-2 text-gray-500">{status}</span>
        </h3>
      </div>

      {/* Scrollable main content */}
      <div className="flex-1 overflow-y-auto space-y-6">
        {/* Instructions for scene generation */}
        <div>
          <label className="block text-sm font-medium mb-2">Scene Generation Instructions</label>
          <AutoResizeTextArea
            value={instructions}
            onChange={(value) => setInstructions(value)}
            minNumberOfRows={1}
          />
        </div>

        {/* Display the generated scene */}
        <div>
          <label className="block text-sm font-medium mb-2">Generated Scene</label>
          {generatedScene && (
            <DynamicForm data={generatedScene} setData={setGeneratedScene} minNumberOfRows={1} />
          )}
        </div>
      </div>

      {/* Bottom area with Regenerate button (pinned, non-scrolling) */}
      <div className="mt-4 shrink-0">
        <SpokableButton
          onClick={handleRegenerate}
          disabled={isGenerating}
          className="inline-flex items-center"
        >
          {isGenerating && <ArrowPathIcon className="w-4 h-4 mr-2 animate-spin" />}
          {isGenerating ? "Generating..." : "Regenerate"}
        </SpokableButton>
      </div>
    </div>
  );
};

export default GenerateScene;
