import React, { useCallback } from "react";
import { BaseFlow } from "@/components/admin/storyGraph/common/BaseFlow";
import {
  MOMENT_NODE_TYPES,
  MomentEdge,
  MomentNode,
} from "@/components/admin/storyGraph/common/types";
import MomentSidebar from "@/components/admin/storyGraph/momentLevel/MomentSidebar";
import { ViewState } from "@/components/admin/storyGraph/StoryGraphContainer";
import { useStoryGraph } from "@/components/admin/storyGraph/common/useGraphData.ts";
import PropertyPanel from "@/components/admin/storyGraph/common/PropertyPanel.tsx";
import MomentDetail from "@/pages/admin/MomentDetail.tsx";
import GenerateMomentsModal from "@/components/admin/generateMomentsModal/GenerateMomentsModal.tsx";
import { modalMomentsGenerateState } from "@/states/ModalState.ts";
import { useRecoilState } from "recoil";

interface MomentFlowProps {
  currentView: ViewState;
  setCurrentView: (view: ViewState) => void;
  storyId: string;
}

export const MomentFlow: React.FC<MomentFlowProps> = ({ storyId, currentView, setCurrentView }) => {
  const {
    story,
    characters,
    // Moment specific data and handlers
    momentNodes,
    momentEdges,
    selectedMomentNode,
    setSelectedMomentNode,
    onMomentNodesChange,
    onMomentEdgesChange,
    onMomentConnect,
    onMomentsDrop,
    onMomentNodeDelete,
    onMomentEdgeDelete,
    onFlowLayout,
    updateMomentNode,

    isLoading,
    fetchingErrorMessage,
  } = useStoryGraph(storyId);

  const [isModalMomentsGenerateOpen] = useRecoilState(modalMomentsGenerateState);

  const onNodeClick = useCallback(
    (_: React.MouseEvent, node: MomentNode) => {
      setSelectedMomentNode(node);
    },
    [setSelectedMomentNode],
  );

  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (fetchingErrorMessage) {
    return <div>Error: {fetchingErrorMessage}</div>;
  }

  // Filter nodes to only show moments for the current scene
  const sceneMoments = momentNodes.filter((node) => node.data.sceneId === currentView.scene?.id);

  return (
    <>
      <BaseFlow<MomentNode, MomentEdge>
        nodes={sceneMoments}
        edges={momentEdges}
        onNodesChange={onMomentNodesChange}
        onEdgesChange={onMomentEdgesChange}
        onConnect={onMomentConnect}
        onNodesDelete={onMomentNodeDelete}
        onEdgesDelete={onMomentEdgeDelete}
        onDrop={onMomentsDrop}
        onLayout={onFlowLayout}
        nodeTypes={MOMENT_NODE_TYPES}
        onNodeClick={onNodeClick}
        sidebar={
          <MomentSidebar
            onDragStart={onDragStart}
            currentView={currentView}
            setCurrentView={setCurrentView}
          />
        }
      />
      <PropertyPanel selectedNode={selectedMomentNode} onClose={() => setSelectedMomentNode(null)}>
        {selectedMomentNode && (
          <MomentDetail
            momentId={selectedMomentNode?.id}
            onMomentUpdate={() => updateMomentNode(selectedMomentNode?.id, selectedMomentNode)}
          />
        )}
      </PropertyPanel>
      {isModalMomentsGenerateOpen && (
        <GenerateMomentsModal story={story} characters={characters} scene={currentView.scene} />
      )}
    </>
  );
};
