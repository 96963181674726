import React from "react";
import { NodeProps } from "@xyflow/react";

import { Sword } from "lucide-react";
import { BaseNode } from "@/components/admin/storyGraph/common/BaseNode.tsx";
import { MomentNode } from "@/components/admin/storyGraph/common/types.ts";

const GateMomentNode: React.FC<NodeProps<MomentNode>> = (props) => {
  return (
    <BaseNode borderColor="chucknorris" {...props}>
      <div className="flex items-center justify-center gap-2 relative">
        <Sword className="w-5 h-5 text-purple-500" />
        <span>{props.data.label}</span>
      </div>
    </BaseNode>
  );
};

export default GateMomentNode;
