import React, { useState } from "react";
import { SceneFlow } from "@/components/admin/storyGraph/sceneLevel/SceneFlow.tsx";
import { MomentFlow } from "@/components/admin/storyGraph/momentLevel/MomentFlow.tsx";
import { Tables } from "@/types/database.ts";

export interface ViewState {
  type: "scene" | "moment";
  scene?: Tables<"blueprint_scenes"> | null | undefined;
}

interface StoryGraphContainerProps {
  storyId: string;
}

export const StoryGraphContainer: React.FC<StoryGraphContainerProps> = ({ storyId }) => {
  const [currentView, setCurrentView] = useState<ViewState>({ type: "scene" });

  return (
    <div className="flex flex-col h-screen">
      <div className="flex items-center gap-2">{currentView.type === "moment" && <></>}</div>

      {currentView.type === "scene" ? (
        <SceneFlow
          storyId={storyId}
          onSceneDoubleClick={(scene) =>
            setCurrentView({
              type: "moment",
              scene: scene,
            })
          }
        />
      ) : (
        <MomentFlow storyId={storyId} currentView={currentView} setCurrentView={setCurrentView} />
      )}
    </div>
  );
};
