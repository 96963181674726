import { useState } from "react";
import { Tables } from "@/types/database.ts";
import { supabase } from "@/vendor/supabaseClient.ts";

export const useFetchStory = () => {
  const [story, setStory] = useState<Tables<"blueprint_stories"> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchStory = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("blueprint_stories")
        .select("*")
        .eq("id", storyId)
        .returns<Tables<"blueprint_stories">[]>()
        .single();

      if (error) {
        throw new Error("Error fetching story: " + error.message);
      }
      setStory(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchStory:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchStory, story, error, isLoading };
};

export const useFetchTransitions = () => {
  const [transitions, setTransitions] = useState<Tables<"blueprint_moment_transitions">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTransitions = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("blueprint_moment_transitions")
        .select("*")
        .eq("blueprint_story_id", storyId);

      if (error) {
        throw new Error("Error fetching transitions: " + error.message);
      }
      setTransitions(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchTransitions:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchTransitions, transitions, error, isLoading };
};

export const useFetchMoments = () => {
  const [moments, setMoments] = useState<Tables<"blueprint_moments">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMoments = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("blueprint_moments")
        .select("*")
        .eq("blueprint_story_id", storyId)
        .order("created_at", { ascending: true });

      if (error) {
        throw new Error("Error fetching moments: " + error.message);
      }

      setMoments(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchMoments:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchMoments, moments, error, isLoading };
};

export const useFetchScenes = () => {
  const [scenes, setScenes] = useState<Tables<"blueprint_scenes">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchScenes = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("blueprint_scenes")
        .select("*")
        .eq("story_id", storyId)
        .order("created_at", { ascending: true });
      if (error) {
        throw new Error("Error fetching scenes: " + error.message);
      }
      setScenes(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchScenes:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchScenes, scenes, error, isLoading };
};

export const useUpdateScene = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateScene = async (sceneData: Tables<"blueprint_scenes">) => {
    if (!sceneData.id) return;
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("blueprint_scenes")
        .update(sceneData)
        .eq("id", sceneData.id)
        .select()
        .single();

      if (error) {
        throw new Error("Error updating scene: " + error.message);
      }

      return data;
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in updateScene:", err);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return { updateScene, error, isLoading };
};

export const useFetchBeats = () => {
  const [beats, setBeats] = useState<Tables<"blueprint_beats">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBeats = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("blueprint_beats")
        .select("*")
        .order("created_at", { ascending: true });
      if (error) {
        throw new Error("Error fetching beats: " + error.message);
      }
      setBeats(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchBeats:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchBeats, beats, error, isLoading };
};

export const useFetchBeatsheets = () => {
  const [beatsheets, setBeatsheets] = useState<Tables<"blueprint_beatsheets">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBeatsheets = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("blueprint_beatsheets")
        .select("*")
        .eq("story_id", storyId)
        .order("created_at", { ascending: true });
      if (error) {
        throw new Error("Error fetching beatsheets: " + error.message);
      }
      setBeatsheets(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchBeatsheets:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchBeatsheets, beatsheets, error, isLoading };
};

export const useFetchBranches = () => {
  const [branches, setBranches] = useState<Tables<"blueprint_branches">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBranches = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("blueprint_branches")
        .select("*")
        .eq("story_id", storyId)
        .order("created_at", { ascending: true });
      if (error) {
        throw new Error("Error fetching branches: " + error.message);
      }
      setBranches(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchBranches:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchBranches, branches, error, isLoading };
};

export const useFetchBranchTransition = () => {
  const [branchTransitions, setBranchTransitions] = useState<
    Tables<"blueprint_branch_transitions">[]
  >([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBranchTransitions = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("blueprint_branch_transitions")
        .select("*")
        .eq("story_id", storyId)
        .order("created_at", { ascending: true });
      if (error) {
        throw new Error("Error fetching branches: " + error.message);
      }
      setBranchTransitions(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchBranches:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchBranchTransitions, branchTransitions, error, isLoading };
};

export const useFetchCharacters = () => {
  const [characters, setCharacters] = useState<Tables<"blueprint_characters">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCharacters = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("blueprint_characters")
        .select("*")
        .eq("blueprint_story_id", storyId)
        .order("created_at", { ascending: true });
      if (error) {
        throw new Error("Error fetching characters: " + error.message);
      }
      setCharacters(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchCharacters:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchCharacters, characters, error, isLoading };
};
