// BaseFlow.tsx
import React, { DragEvent } from "react";

import "@xyflow/react/dist/style.css";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import {
  Background,
  Controls,
  Edge,
  MiniMap,
  Node,
  NodeMouseHandler,
  NodeTypes,
  OnConnect,
  OnEdgesChange,
  OnEdgesDelete,
  OnNodesChange,
  OnNodesDelete,
  Panel,
  ReactFlow,
} from "@xyflow/react";

interface BaseFlowProps<NodeType extends Node, EdgeType extends Edge> {
  nodes: NodeType[];
  edges: EdgeType[];
  nodeTypes: NodeTypes;
  onNodesChange: OnNodesChange<NodeType>;
  onEdgesChange: OnEdgesChange<EdgeType>;
  onNodesDelete: OnNodesDelete<NodeType>;
  onEdgesDelete: OnEdgesDelete<EdgeType>;
  onConnect: OnConnect;
  onDrop: (event: DragEvent<HTMLDivElement>) => void;
  onNodeClick: NodeMouseHandler<NodeType>;
  onNodeDoubleClick?: NodeMouseHandler<NodeType>;
  onLayout: () => void;
  sidebar: React.ReactNode;
}

export function BaseFlow<NodeType extends Node, EdgeType extends Edge>({
  nodes,
  edges,
  nodeTypes,
  onNodesChange,
  onEdgesChange,
  onNodesDelete,
  onEdgesDelete,
  onConnect,
  onDrop,
  onNodeClick,
  onNodeDoubleClick,
  onLayout,
  sidebar,
}: BaseFlowProps<NodeType, EdgeType>) {
  console.log("BaseFlow rendering with:", { nodes, edges });
  return (
    <div className="flex flex-1">
      {sidebar}
      <div className="flex-1">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodesDelete={onNodesDelete}
          onDrop={onDrop}
          onEdgesDelete={onEdgesDelete}
          onDragOver={(e) => e.preventDefault()}
          onNodeClick={onNodeClick}
          onNodeDoubleClick={onNodeDoubleClick}
          nodeTypes={nodeTypes}
          defaultViewport={{ x: 100, y: 100, zoom: 0.7 }}
          fitViewOptions={{
            padding: 0.2,
            minZoom: 0.5,
            maxZoom: 2,
          }}
          defaultEdgeOptions={{
            type: "smoothstep",
            animated: true,
          }}
        >
          <Panel position="top-right">
            <SpokableButton onClick={onLayout}>Layout</SpokableButton>
          </Panel>
          <Background />
          <Controls />
          <MiniMap />
        </ReactFlow>
      </div>
    </div>
  );
}
