import React, { useCallback, useEffect, useRef, useState } from "react";
import { SCENE_NODE_TYPES, SceneEdge, SceneNode } from "../common/types";
import { BaseFlow } from "@/components/admin/storyGraph/common/BaseFlow";
import SceneSidebar from "@/components/admin/storyGraph/sceneLevel/SceneSidebar";
import { useStoryGraph } from "@/components/admin/storyGraph/common/useGraphData.ts";
import PropertyPanel from "@/components/admin/storyGraph/common/PropertyPanel.tsx";
import GenerateScenesModal from "@/components/admin/generateScenesModal/GenerateScenesModal.tsx";
import { useRecoilState } from "recoil";
import { modalScenesGenerateState } from "@/states/ModalState.ts";
import { getScenesPathToNode } from "@/components/admin/storyGraph/util/reactFlowUtil.ts";
import DynamicForm from "@/components/admin/DynamicForm.tsx";
import { Tables } from "@/types/database.ts";

interface SceneFlowProps {
  storyId: string;
  onSceneDoubleClick: (scene: Tables<"blueprint_scenes"> | null | undefined) => void;
}

const CLICK_DELAY = 250;

export const SceneFlow: React.FC<SceneFlowProps> = ({ storyId, onSceneDoubleClick }) => {
  const clickTimeoutRef = useRef<number | null>(null);

  const {
    story,
    branchTransitions,
    branches,
    beats,
    beatsheets,
    characters,
    // Scene specific data and handlers
    sceneNodes,
    sceneEdges,
    selectedSceneNode,
    setSelectedSceneNode,
    onSceneNodesChange,
    onSceneEdgesChange,
    onSceneConnect,
    onScenesDrop,
    onScenesLayout,
    onSceneNodeDelete,
    onSceneEdgeDelete,
    updateScene,
    // Shared state
    isLoading,
    fetchingErrorMessage,
    refreshData,
  } = useStoryGraph(storyId);

  const [isModalScenesGenerateOpen, setModalScenesGenerateOpen] =
    useRecoilState(modalScenesGenerateState);

  const [sceneData, setSceneData] = useState(selectedSceneNode?.data.scene);

  useEffect(() => {
    if (selectedSceneNode) {
      setSceneData(selectedSceneNode.data.scene);
    }
  }, [selectedSceneNode]);

  useEffect(() => {
    if (selectedSceneNode && sceneData) {
      const updatedNode = {
        ...selectedSceneNode,
        data: {
          ...selectedSceneNode.data,
          scene: sceneData,
        },
      };
      setSelectedSceneNode(updatedNode);
    }
  }, [sceneData]);

  const handleSingleClick = useCallback(
    (node: SceneNode) => {
      console.log("handleSingleClick", node);
      setSelectedSceneNode(node as SceneNode);
      if (!node.data.isInitialized) {
        setModalScenesGenerateOpen(true);
      }
    },
    [setSelectedSceneNode, setModalScenesGenerateOpen],
  );

  const handleDoubleClick = useCallback(
    (node: SceneNode) => {
      if (node.data.isInitialized) {
        onSceneDoubleClick(node.data.scene);
      } else {
        handleSingleClick(node);
      }
    },
    [onSceneDoubleClick, handleSingleClick],
  );

  const onNodeClick = useCallback(
    (_: React.MouseEvent, node: SceneNode) => {
      console.log("node", node);
      if (clickTimeoutRef.current) {
        window.clearTimeout(clickTimeoutRef.current);
        clickTimeoutRef.current = null;
      }
      clickTimeoutRef.current = window.setTimeout(() => {
        handleSingleClick(node);
        clickTimeoutRef.current = null;
      }, CLICK_DELAY);
    },
    [handleSingleClick],
  );

  const onNodeDoubleClick = useCallback(
    (_: React.MouseEvent, node: SceneNode) => {
      console.log("handleSingleClick", node);
      if (clickTimeoutRef.current) {
        window.clearTimeout(clickTimeoutRef.current);
        clickTimeoutRef.current = null;
      }
      handleDoubleClick(node);
    },
    [handleDoubleClick],
  );

  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (fetchingErrorMessage) {
    return <div>Error: {fetchingErrorMessage}</div>;
  }

  return (
    <>
      <BaseFlow<SceneNode, SceneEdge>
        nodes={sceneNodes}
        edges={sceneEdges}
        onNodesChange={onSceneNodesChange}
        onEdgesChange={onSceneEdgesChange}
        onConnect={onSceneConnect}
        onNodesDelete={onSceneNodeDelete}
        onEdgesDelete={onSceneEdgeDelete}
        onDrop={onScenesDrop}
        onLayout={onScenesLayout}
        nodeTypes={SCENE_NODE_TYPES}
        onNodeClick={onNodeClick}
        onNodeDoubleClick={onNodeDoubleClick}
        sidebar={<SceneSidebar onDragStart={onDragStart} />}
      />
      <PropertyPanel
        selectedNode={selectedSceneNode}
        onClose={async () => {
          if (selectedSceneNode?.data.scene) await updateScene(selectedSceneNode?.data.scene);
          setSelectedSceneNode(null);
          refreshData();
        }}
      >
        {selectedSceneNode && (
          <DynamicForm
            data={sceneData}
            setData={setSceneData}
            filterDates={true}
            filterIds={true}
          />
        )}
      </PropertyPanel>
      {isModalScenesGenerateOpen && (
        <GenerateScenesModal
          story={story}
          beats={beats}
          beatsheets={beatsheets}
          branches={branches}
          characters={characters}
          clickedBranch={
            selectedSceneNode?.data.branch ? selectedSceneNode?.data.branch : branches[0]
          }
          clickedBeatsheet={selectedSceneNode?.data.beatsheet}
          branchTransitions={branchTransitions}
          previousScenes={getScenesPathToNode(sceneNodes, sceneEdges, selectedSceneNode?.id)}
          onCloseCallback={refreshData}
        />
      )}
    </>
  );
};
