import { SceneEdgeData, SceneNodeData } from "@/components/admin/storyGraph/common/types.ts";
import { Tables } from "@/types/database.ts";
import { Edge, Node } from "@xyflow/react";

/**
 * Returns an ordered array of `scene` objects (Tables<"blueprint_scenes">) from
 * the **first root node** (the first node with no incoming edges) up to (but NOT including)
 * the `targetNodeId`.
 *
 * @param nodes - The array of React Flow nodes (each with an `id` and possibly `data.scene`).
 * @param edges - The array of React Flow edges (each with `source` and `target`).
 * @param targetNodeId - The ID of the target node (UUID or any string).
 * @returns An array of `BlueprintScene` from the first root node to right before the target node.
 *          If no path is found or no root exists, returns an empty array.
 */
export function getScenesPathToNode(
  nodes: Node<SceneNodeData>[],
  edges: Edge<SceneEdgeData>[],
  targetNodeId: string | undefined,
): Tables<"blueprint_scenes">[] {
  if (!nodes || !edges) return [];

  // 1) Identify the first node with no incoming edges (the "root" node)
  const rootNode = nodes.find((node) => {
    const hasIncomingEdge = edges.some((edge) => edge.target === node.id);
    return !hasIncomingEdge;
  });

  // If no root node is found, return an empty array
  if (!rootNode) {
    return [];
  }

  const rootNodeId = rootNode.id;

  // 2) Build a Map for quick lookups (id -> Node)
  const nodeById = new Map<string, Node<SceneNodeData>>(nodes.map((n) => [n.id, n]));

  // 3) Create adjacency list for BFS
  //    adjacencyList[sourceId] = array of targetIds
  const adjacencyList = new Map<string, string[]>();
  for (const edge of edges) {
    if (!adjacencyList.has(edge.source)) {
      adjacencyList.set(edge.source, []);
    }
    adjacencyList.get(edge.source)!.push(edge.target);
  }

  // 4) BFS from `rootNodeId` to `targetNodeId`
  const queue: string[] = [rootNodeId];
  // This map tells us how we reached each node (child -> parent)
  const parentMap = new Map<string, string | null>([[rootNodeId, null]]);

  let found = false;

  while (queue.length > 0) {
    const currentId = queue.shift()!;
    if (currentId === targetNodeId) {
      found = true;
      break;
    }

    const neighbors = adjacencyList.get(currentId) || [];
    for (const neighborId of neighbors) {
      if (!parentMap.has(neighborId)) {
        parentMap.set(neighborId, currentId);
        queue.push(neighborId);
      }
    }
  }

  if (!found) {
    // No path from root to target
    return [];
  }

  // 5) Reconstruct path from `targetNodeId` back to `rootNodeId`
  const pathIds: string[] = [];
  let current: string | undefined | null = targetNodeId;
  while (current) {
    pathIds.push(current);
    current = parentMap.get(current) || null;
  }

  // That yields [targetNodeId, ..., rootNodeId], so reverse it
  pathIds.reverse(); // => [rootNodeId, ..., targetNodeId]

  // Remove the target node from the path so its scene is NOT included
  pathIds.pop(); // => [rootNodeId, ..., nodeBeforeTarget]

  // 6) Convert node IDs to their `scene` objects (BlueprintScene)
  const scenesPath: Tables<"blueprint_scenes">[] = [];
  for (const nodeId of pathIds) {
    const node = nodeById.get(nodeId);
    // Only push if node has a scene
    if (node?.data?.scene) {
      scenesPath.push(node.data.scene);
    }
  }

  return scenesPath;
}
