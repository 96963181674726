import React from "react";
import { Circle, Diamond, Square } from "lucide-react";
import { SceneType } from "@/components/admin/storyGraph/common/types";
import DraggableCard from "@/components/admin/storyGraph/common/DraggableCard.tsx";

interface SceneSidebarProps {
  onDragStart: (event: React.DragEvent<HTMLDivElement>, nodeType: string) => void;
}

const SceneSidebar: React.FC<SceneSidebarProps> = ({ onDragStart }) => {
  const cards = [
    { icon: Square, label: "Scene", nodeType: "scene" as SceneType },
    { icon: Diamond, label: "Gate", nodeType: "gate" as SceneType },
    { icon: Circle, label: "Nexus", nodeType: "nexus" as SceneType },
  ];

  return (
    <div className="w-1/5 px-6 pt-6 border-r">
      <div className="space-y-4">
        {cards.map((card) => (
          <DraggableCard
            key={card.nodeType}
            icon={card.icon}
            label={card.label}
            nodeType={card.nodeType}
            onDragStart={onDragStart}
          />
        ))}
      </div>
    </div>
  );
};

export default SceneSidebar;
