import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import { Tables, TablesInsert } from "../../../types/database.ts";

import { Badge } from "../../catalyst/badge.tsx";
import { mergeBeatsAndBeatsheets } from "../../../utils/valueObject.ts";
import GenerateScenes from "./GenerateScenes.tsx";
import SaveAll from "./SaveAll.tsx";
import { unSnakeCase } from "@/utils/stringUtil.ts";
import { SpokableButton } from "../SpokableButton.tsx";
import SelectBeat from "@/components/admin/generateScenesModal/SelectBeat.tsx";

export interface GenerateBeatModalProps {
  story: Tables<"blueprint_stories"> | null;
  beats: Tables<"blueprint_beats">[];
  beatsheets: Tables<"blueprint_beatsheets">[];
  branches: Tables<"blueprint_branches">[];
  characters: Tables<"blueprint_characters">[];
  clickedBranch: Tables<"blueprint_branches"> | null | undefined;
  clickedBeatsheet: Tables<"blueprint_beatsheets"> | null | undefined;
  branchTransitions: Tables<"blueprint_branch_transitions">[] | null;
  previousScenes: Tables<"blueprint_scenes">[] | null;
  onCloseCallback: () => void;
}

const GenerateScenesModal: React.FC<GenerateBeatModalProps> = ({
  story,
  beats,
  beatsheets,
  characters,
  clickedBranch,
  clickedBeatsheet,
  previousScenes,
  onCloseCallback,
}) => {
  const {
    isOpen,
    stepIndex,
    setStepIndex,
    handleClose,
    generatedBeatsheet,
    beatsheetStatus,
    generateBeatsheet,
    generatedScene,
    sceneStatus,
    generateScene,
    setGeneratedScene,
  } = useGenerateSteps();

  const maxStepIndex = 2;

  const closePopup = () => {
    handleClose();
    onCloseCallback();
  };

  const [selectedBeatsheet, setSelectedBeatsheet] =
    useState<TablesInsert<"blueprint_beatsheets"> | null>(null);

  const renderStepContent = () => {
    switch (stepIndex) {
      case 0:
        return (
          <SelectBeat
            story={story}
            status={beatsheetStatus}
            beats={beats}
            beatsheets={beatsheets}
            branch={clickedBranch}
            characters={characters}
            previousScenes={previousScenes}
            setSelectedBeatsheet={setSelectedBeatsheet}
            generateBeatsheet={generateBeatsheet}
            generatedBeatsheet={generatedBeatsheet}
            selectedBeatsheet={selectedBeatsheet}
          />
        );
      case 1:
        return (
          <GenerateScenes
            story={story}
            status={sceneStatus}
            generateScene={generateScene}
            setGeneratedScene={setGeneratedScene}
            generatedScene={generatedScene}
            characters={characters}
            branch={clickedBranch}
            selectedBeatsheet={selectedBeatsheet}
            previousScenes={previousScenes}
            previousBeatsheets={beatsheets}
          />
        );

      case 2:
        return (
          <SaveAll
            generatedScene={generatedScene}
            story={story}
            clickedBranch={clickedBranch}
            selectedBeatsheet={selectedBeatsheet}
            previousScenes={previousScenes}
            handleClose={closePopup}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 font-nunito" onClose={handleClose}>
        <div className="fixed inset-0 bg-black/60" aria-hidden="true" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6">
            <Dialog.Panel className="w-2/3 max-h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all">
              {clickedBranch && clickedBeatsheet && (
                <div className="pt-6 ml-6">
                  <Badge>{clickedBranch.name}</Badge>
                  <Badge className="ml-2">
                    {unSnakeCase(
                      mergeBeatsAndBeatsheets(beats, [clickedBeatsheet]).pop()?.beat_name || "",
                    )}
                  </Badge>
                </div>
              )}
              <div className="p-6">
                {renderStepContent()}
                <div className="mt-4 flex justify-between">
                  <div>
                    {stepIndex > 0 && (
                      <SpokableButton
                        color="light"
                        onClick={() => setStepIndex(Math.max(0, stepIndex - 1))}
                        className="mr-2"
                      >
                        Previous
                      </SpokableButton>
                    )}
                    {stepIndex < maxStepIndex && (
                      <SpokableButton onClick={() => setStepIndex(stepIndex + 1)}>
                        Next
                      </SpokableButton>
                    )}
                  </div>
                  <SpokableButton color="light" onClick={handleClose}>
                    Close
                  </SpokableButton>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GenerateScenesModal;
