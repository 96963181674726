// src/components/admin/storyGraph/layout/useConnectionHandling.ts
import { useCallback } from "react";

import { StoryGraphEdge, StoryGraphNode } from "../common/types";
import { Connection, Edge, XYPosition } from "@xyflow/react";

interface ConnectionHandlingProps<TEdge extends Edge> {
  nodes: StoryGraphNode[];
  edges: TEdge[];
  setEdges: React.Dispatch<React.SetStateAction<TEdge[]>>;
}

export const useConnectionHandling = <TEdge extends Edge>({
  nodes,
  edges,
  setEdges,
}: ConnectionHandlingProps<TEdge>) => {
  const onConnect = useCallback(
    (params: Connection) => {
      setEdges((eds: TEdge[]) => {
        return [
          ...eds,
          {
            ...params,
            id: `e${params.source}-${params.target}`,
            type: "smoothstep",
          } as TEdge,
        ];
      });
    },
    [setEdges],
  );

  const createEdgeFromLastNode = useCallback(
    (newNodeId: string) => {
      if (nodes.length === 0) return [];

      const lastNode = nodes[nodes.length - 1];
      const newEdge: StoryGraphEdge = {
        id: `e${lastNode.id}-${newNodeId}`,
        source: lastNode.id,
        target: newNodeId,
        type: "smoothstep",
      };

      return [newEdge];
    },
    [nodes],
  );

  const findNodeAtPosition = useCallback(
    (position: XYPosition) => {
      return nodes.find((node) => {
        const nodeWidth = 160;
        const nodeHeight = 60;

        return (
          position.x >= node.position.x &&
          position.x <= node.position.x + nodeWidth &&
          position.y >= node.position.y &&
          position.y <= node.position.y + nodeHeight
        );
      });
    },
    [nodes],
  );

  const handleNodeInsertion = useCallback(
    (targetNode: StoryGraphNode | undefined, newNodeId: string) => {
      if (!targetNode) {
        return [...edges, ...createEdgeFromLastNode(newNodeId)];
      }

      const existingEdge = edges.find((edge) => edge.source === targetNode.id);
      if (!existingEdge) {
        return [
          ...edges,
          {
            id: `e${targetNode.id}-${newNodeId}`,
            source: targetNode.id,
            target: newNodeId,
            type: "smoothstep",
          } as StoryGraphEdge,
        ];
      }

      // Remove existing edge and create two new edges
      const updatedEdges = edges.filter((edge) => edge.id !== existingEdge.id);
      return [
        ...updatedEdges,
        {
          id: `e${targetNode.id}-${newNodeId}`,
          source: targetNode.id,
          target: newNodeId,
          type: "smoothstep",
        } as StoryGraphEdge,
        {
          id: `e${newNodeId}-${existingEdge.target}`,
          source: newNodeId,
          target: existingEdge.target,
          type: "smoothstep",
        } as StoryGraphEdge,
      ];
    },
    [edges, createEdgeFromLastNode],
  );

  return {
    onConnect,
    createEdgeFromLastNode,
    findNodeAtPosition,
    handleNodeInsertion,
  };
};
